@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.widget-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

:global(.main-page-redesign) .widget-content {
    .screen-gt-s({
        height: 388px;
    });
}

.growing-footer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.additional-placeholder {
    position: absolute;
    bottom: -16px;
    right: 8px;
    width: 56px;
    height: 35px;
    background: url('./assets/additional-placeholder.svg') center / contain no-repeat;

    .screen-gt-xs({
        width: 108px;
        height: 62px;
        bottom: -28px;
        right: 32px;
    });
}

.additional-placeholder_zp {
    width: 36px;
    height: 50px;
    right: 16px;
    bottom: -12px;
    background-image: url('./assets/additional-placeholder_zp.svg');

    .screen-gt-xs({
        width: 36px;
        height: 50px;
        right: 64px;
        bottom: -12px;
    });
}
