.placeholder-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.placeholder-content {
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
