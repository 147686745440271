@import '@hh.ru/magritte-ui/tokens';

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
}

:global(.main-page-redesign) .footer {
    padding: 12px;
    box-sizing: border-box;
    height: 64px;
}

.footer-button-content {
    display: flex;
    gap: 8px;
    justify-content: center;
}
