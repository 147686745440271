@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 12px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.container-horizontal {
    flex-direction: row;
    overflow-x: scroll;
    width: auto;
}

.card-horizontal {
    width: 230px;

    .screen-gt-xs({
        width: auto
    });
}
