@import '@hh.ru/magritte-ui/tokens';

.qr-code,
.qr-code-container {
    width: 128px;
    height: 128px;
}

.card-flipper-container {
    height: 100%;
    position: relative;
    perspective: 500px;
}

.card-flipper {
    position: absolute;
    width: 100%;
    min-height: 210px;
    height: 100%;
    top: 0;
    left: 0;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.card-flipper-flipped {
    transform: rotateY(180deg);
    transition: transform 0.5s;
}

.card-front,
.card-back {
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 24px;
    backface-visibility: hidden;
    background-color: @magritte-color-background-secondary;
}

.card-front {
    padding: 12px;
}

.card-back {
    transform: rotateY(180deg);
    padding-top: 40px;
}

.card-back-content {
    display: flex;
    justify-content: center;
}
