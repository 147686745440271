@import '@hh.ru/magritte-ui/tokens';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid @magritte-color-stroke-neutral;
}

:global(.main-page-redesign) .header {
    padding: 20px 24px;
    height: 66px;
    box-sizing: border-box;
}
