@import '@hh.ru/magritte-ui/breakpoints';

.illustration {
    display: block;
    z-index: 2;
}

.illustration-small {
    background-image: url('./img/small.png');
    background-size: 297px 251px;
    width: 297px;
    min-width: 297px;
    height: 251px;
}

.illustration-medium {
    background-image: url('./img/medium.png');
    background-size: 382px 184px;
    width: 382px;
    min-width: 382px;
    height: 184px;

    .screen-gt-m({
    background-size: 420px 226px;
    width: 420px;
    min-width: 420px;
    height: 226px;
  });
}

.illustration-large {
    background-image: url('./img/large.png');
    background-size: 528px 256px;
    width: 528px;
    min-width: 527px;
    height: 256px;
}
