@import '@hh.ru/magritte-ui/breakpoints';

.item-container {
    width: 100%;
    flex-shrink: 0;
}

.container {
    height: 204px;
    min-height: 204px;
    overflow: hidden;

    .screen-gt-xs({
        height: 178px;
        min-height: 178px;
    });

    .screen-gt-s({
        height: 260px;
        min-height: 260px;
    });
}
