@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    img {
        object-fit: contain;
    }
}

.container-horizontal {
    width: 230px;

    .screen-gt-xs({
        width: auto
    });
}
